import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'gatsby';
import styled from 'styled-components';
import moment from 'moment';

const StyledRelatedNewsArticles = styled.div`
  background-image: ${props =>
    `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.28), rgba(0, 0, 0, 0.8)),
      url(${props.coverImage})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 12px;
  margin: 0px 8px 16px;

  .styled-source {
    color: ${props => props.theme.whitePalette.original};
    letter-spacing: 1px;
    margin-bottom: 4px;
  }
  .title {
    line-height: 22px;
    font-size: 22px;
    font-weight: 600;
  }

  .styled-author {
    color: ${props => props.theme.whitePalette.light};
    font-size: 14px;
  }

  h5 {
    color: ${props => props.theme.primeYellowPalette.original};
    margin-bottom: 4px;
  }
`;

function PressRoomRelatedArticles({ articles, newsId }) {
  const newArticles = articles.filter(value => value.strapiId !== newsId);
  return (
    <Row type="flex" justify="center">
      {newArticles.slice(0, 3).map(data => (
        <Col lg={24} md={8} sm={18} xs={24} key={data.strapiId}>
          <Link to={`/press-room/${data.url_slug}`}>
            <StyledRelatedNewsArticles
              coverImage={`${process.env.IMAGE_ASSETS}/news-archive/${data.picture}`}
            >
              <div className="styled-source">
                {data.source_name.toUpperCase()}
              </div>
              <h5 className='title'>{data.news_title}</h5>
              <div className="styled-author">
                {data.author} •{' '}
                {moment(data.published_date).format('MMMM DD, YYYY')}
              </div>
            </StyledRelatedNewsArticles>
          </Link>
        </Col>
      ))}
    </Row>
  );
}

export default PressRoomRelatedArticles;
