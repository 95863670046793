import React from 'react';
import styled from 'styled-components';
import { Row, Col, List, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const { Item } = List;
const { Meta } = Item;

const StyledPressRoomOtherArticles = styled.div`
  margin: 36px 0px;
  padding: 24px 0px;

  .ant-list-item-meta-title {
    margin-bottom: 8px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .tag {
      display: inline-block;
      padding: 4px;
      margin-bottom: 8px;
      font-size: 12px;
      line-height: 12px;
      background-color: ${props => props.theme.whitePalette.light};
      border-radius: 2px;

      .fa-newspaper {
        margin-right: 4px;
      }
    }

    .title {
      line-height: 16px;
    }

    .date {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: ${props => props.theme.bluePalette.original};
    }
  }

  .styled-button {
    font-size: 14px;
    border-color: ${props => props.theme.bluePalette.original};
    background-color: ${props => props.theme.bluePalette.original};
    margin: 8px 0px;

    :hover {
      border-color: ${props => props.theme.bluePalette.light};
      background-color: ${props => props.theme.bluePalette.light};
    }

    .styled-icon {
      font-size: 12px;
      margin-left: 4px;
    }
  }
`;

function PressRoomOtherArticles({ articles, newsId }) {
  return (
    <StyledPressRoomOtherArticles>
      <Row type="flex" justify="center">
        <Col xxl={18} lg={20} md={22} sm={23} xs={23}>
          <h3>Other News Articles</h3>
          <List
            itemLayout="horizontal"
            dataSource={articles
              .filter(data => data.strapiId !== newsId)
              .slice(3, 8)}
            renderItem={item => (
              <Item>
                <Meta
                  title={
                    <div className="header">
                      <div>
                        <div className="tag">
                          <FontAwesomeIcon icon="newspaper" fixedWidth />
                          {item.source_name}
                        </div>
                        <div className="title">
                          <a href={`/press-room/${item.url_slug}`}>
                            {item.news_title}
                          </a>
                        </div>
                      </div>
                      <div className="date">
                        {moment(item.published_date).format('MMM D, YYYY')}
                      </div>
                    </div>
                  }
                  description={
                    <div>
                      <div>{item.description}</div>
                      <Button
                        type="primary"
                        size="small"
                        className="styled-button"
                        href={`/press-room/${item.url_slug}`}
                      >
                        Read more
                        <FontAwesomeIcon
                          icon="arrow-right"
                          className="styled-icon"
                          fixedWidth
                        />
                      </Button>
                    </div>
                  }
                />
              </Item>
            )}
          />
        </Col>
      </Row>
    </StyledPressRoomOtherArticles>
  );
}

export default PressRoomOtherArticles;
