import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PressRoomViewPageContent from '../components/press-room-page/PressRoomViewPageContent';
import PressRoomOtherArticles from '../components/press-room-page/PressRoomOtherArticles';
import MediaContactsComponent from '../components/press-room/MediaContactsComponent';

function PressRoomViewPage({ pageContext, location }) {
  const data = useStaticQuery(graphql`
    {
      allStrapiNewsArchive(sort: { fields: published_date, order: DESC }) {
        nodes {
          visibility
          tags
          source_name
          strapiId
          picture
          published_date
          news_title
          id
          description
          content
          author
          url_slug
        }
      }
    }
  `);

  const articles = data.allStrapiNewsArchive.nodes;
  return (
    <Layout>
      <SEO
        title={pageContext.newsDetails.news_title}
        description={pageContext.newsDetails.description}
        meta={[
          {
            property: 'og:image',
            content: `${process.env.IMAGE_ASSETS}/news-archive/${pageContext.newsDetails.picture}`,
          },
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content: pageContext.newsDetails.tags,
          },
        ]}
      />
      <PressRoomViewPageContent
        newsDetails={pageContext.newsDetails}
        articles={articles}
        postUrl={`https://primephilippines.com/press-room/${pageContext.newsDetails.url_slug}`}
      />
      <PressRoomOtherArticles
        newsId={pageContext.newsDetails.strapiId}
        articles={articles}
      />
      <MediaContactsComponent />
    </Layout>
  );
}

export default PressRoomViewPage;
